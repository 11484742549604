/*

Zenburn style from voldmar.ru (c) Vladimir Epifanov <voldmar@voldmar.ru>
based on dark.css by Ivan Sagalaev

*/

.hljs {
  display: block;
  overflow-x: auto;
  padding: 0.5em;
  background: #3f3f3f;
  color: #dcdcdc;
}

.hljs-keyword,
.hljs-selector-tag,
.hljs-tag {
  color: #e3ceab;
}

.hljs-template-tag {
  color: #dcdcdc;
}

.hljs-number {
  color: #8cd0d3;
}

.hljs-variable,
.hljs-template-variable,
.hljs-attribute {
  color: #efdcbc;
}

.hljs-literal {
  color: #efefaf;
}

.hljs-subst {
  color: #8f8f8f;
}

.hljs-title,
.hljs-name,
.hljs-selector-id,
.hljs-selector-class,
.hljs-section,
.hljs-type {
  color: #efef8f;
}

.hljs-symbol,
.hljs-bullet,
.hljs-link {
  color: #dca3a3;
}

.hljs-deletion,
.hljs-string,
.hljs-built_in,
.hljs-builtin-name {
  color: #cc9393;
}

.hljs-addition,
.hljs-comment,
.hljs-quote,
.hljs-meta {
  color: #7f9f7f;
}


.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}
